<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import DevicePixelRatio from "@/utils/device.js";
export default {
  name: "DASDINGSGCBigScreenApp",

  data() {
    return {};
  },

  mounted() {},
  created() {
    //  new DevicePixelRatio().init()
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
<style>
.amap-logo {
  display: none !important; 
}
.amap-copyright {
  opacity: 0; 
}
</style>
