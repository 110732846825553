<template>
  <div class="header touch-enable">
    <i class="header-content-bg"></i>
    <!-- <i class="header-content-bg-two"></i>  -->
    <span class="header-title-text" @click="quanping">{{
      commonHeadTitle
    }}</span>
    <div class="header_se_ont">
      <div class="header_left_se">
        <div
          class="basics_bg"
          :class="{
            'margin-lect': index == 5,
            _let_bg_t: index <= 4 && select_index != index,
            _rig_bg_t: index > 4 && select_index != index,
            _let_bg: index <= 4 && select_index == index,
            _rig_bg: index > 4 && select_index == index,
          }"
          v-for="(item, index) in data_list"
          :key="index"
          @click="select_item(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { data_list } from "./config";
export default {
  data() {
    return {
      data_list: data_list,
    };
  },
  components: {},
  computed: {
    commonHeadTitle() {
      return "乌镇镇横港村-未来乡村";
    },
    select_index() {
      return this.$store.get("select_index") || 0;
    },
  },
  mounted() {
    this.get_path();
  },
  methods: {
    get_path() {
      this.data_list.forEach((item, index) => {
        if (item.path == this.$route.path) {
          this.$store.set("select_index", index);
        }
      });
    },
    back() {
      this.$router.back();
    },
    select_item(item, index) {
      this.$store.set("select_index", index);
      this.$router.push(item.path);
    },
    quanping() {
      let element = document.documentElement;
      // 退出全屏
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      // 进入全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
    },
  },
};
</script>
<style lang="scss">
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: px2vw(1920);
  height: px2vh(122);
  z-index: 2;
}

.header-content-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("~src/assets/images/header-contentnew.png");
  background-size: 100% 100%;
}
.header-content-bg-two {
  position: absolute;
  top: 81%;
  left: 0;
  height: px2vh(30);
  width: px2vh(300);
  background-image: url("~src/assets/images/light.png");
  background-size: 100% 100%;
  // animation: pulsate-fwd 2s ease-in-out infinite both;
  animation: pulsates 4s ease-in-out infinite alternate;
}
.header-title-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: px2vh(-20);
  font-size: px2vh(50);
  font-weight: 400;
  color: #ffffff;
  letter-spacing: px2vh(5);
  font-family: "ysbth";
    pointer-events: auto;
  cursor: pointer;

}
@keyframes pulsate-fwd {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(0.8, 1.25, 1);
    opacity: 1;
  }
  40% {
    transform: scaleY(0.75);
    opacity: 0.5;
  }
  50% {
    transform: scale3d(0.2, 1.15, 1);
    opacity: 0;
  }
  65% {
    transform: scaleY(0.95);
    opacity: 0.5;
  }
  75% {
    transform: scale3d(0.8, 1.05, 1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes pulsates {
  0% {
    left: 34%;
  }
  100% {
    left: 48%;
  }
}
.header_se_ont {
  position: absolute;
  left: 0;
  top: px2vh(3);
  width: px2vw(1920);
  display: flex;

  .header_left_se {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 px2vw(20);
    box-sizing: border-box;
  }
}
.basics_bg {
  width: px2vw(128);
  height: px2vh(48);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: px2vh(24);
  font-family: ysbth;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
._let_bg_t {
  background-image: url("~src/assets/images/1_slices/no_selsect.png");
}
._rig_bg_t {
  background-image: url("~src/assets/images/1_slices/Frame1321314797.png");
}
._let_bg {
  background-image: url("~src/assets/images/1_slices/select_title.png");
  color: #ffffff;
}
._rig_bg {
  background-image: url("~src/assets/images/1_slices/Frame1321314797.png");
  color: #ffffff;
}
.margin-lect {
  margin-left: px2vw(787);
}
</style>
