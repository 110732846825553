import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import layout from "./layout";
import store from "./store";
import "./assets/css/global.scss";
import "swiper/css/swiper.css";
import "./assets/css/swiper.css";
import 'element-ui/lib/theme-chalk/index.css';
import ModuleBox from "@/components/module-box/module-box";
import TagTitle from "@/components/tag-title";
import { Dialog, Carousel, CarouselItem, Image } from 'element-ui';
import dialog_imgtext from "@/components/dialog_imgtext/index.vue";
import "@/assets/font/fonticon/iconfont.css";
Vue.config.productionTip = false;
// 引入 Vconsole
import Vconsole from 'vconsole'

import { borderBox8, loading } from '@jiaminghi/data-view'
import { _set_Arry} from './utils/index.js'

Vue.use(borderBox8)
Vue.use(loading)
// 所有环境均使用
// new Vconsole()

// 在 test 环境才使用
process.env.NODE_ENV === 'development' ? new Vconsole : ''
Vue.prototype._set_Arry = _set_Arry
Vue.prototype.screenHeight = window.innerHeight

Vue.use(Dialog)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(layout);
Vue.use(store);

Vue.component('module-box',ModuleBox)
Vue.component('tag-title',TagTitle)
Vue.component('dialog_imgtext', dialog_imgtext)
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
