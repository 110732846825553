export const data_list = [
    {
        name: "党建",
        path: "/",
    },
    {
        name: "产业",
        path: "/chanye",
    },
    {
        name: "风貌",
        path: "/fengmao_new",
    },
    {
        name: "文化",
        path: "/wenhua",
    },
    {
        name: "邻里",
        path: "/linli",
    },
    {
        name: "健康",
        path: "/jiankang",
    },
    {
        name: "低碳",
        path: "/ziyuan",
    },
    {
        name: "交通",
        path: "/jiaotong",
    },
    {
        name: "智慧",
        path: "/zhihui",
    },
    {
        name: "治理",
        path: "/zhili",
    },
    // {
    //     name: "党建",
    //     path: "/",
    // },
    // {
    //     name: "风貌",
    //     path: "/fengmao",
    // },
    // {
    //     name: "共富",
    //     path: "/gongfu",
    // },
    // {
    //     name: "产业",
    //     path: "/chanye",
    // },
    // {
    //     name: "资源",
    //     path: "/ziyuan",
    // },
    // {
    //     name: "治理",
    //     path: "/zhili",
    // },
    // {
    //     name: "智慧",
    //     path: "/zhihui",
    // },
    // {
    //     name: "文化",
    //     path: "/wenhua",
    // },
]